<template>
    

    <div class="row">
        <div v-if="props.title" class="input-group mb-4">
            <span v-if="props.title" class="input-group-text" id="basic-addon1">{{props.title}}</span>
            <div class="select2">
                <multiselect 
                    v-model="item" 
                    :options="props.options" 
                    placeholder="" 
                    :allow-empty="false"        
                    selected-label=""
                    select-label="" 
                    track-by="value" 
                    label="name" 
                    deselect-label="" 
                    object="false" 
                    class="multiselect-blue"
                    :disabled="props.disabled"
                >
                </multiselect>
            </div>
        </div>
    </div>
    <div v-if="!props.title"  class="row">
            <div class="select2">
                <multiselect 
                    v-model="item" 
                    :options="props.options" 
                    placeholder="" 
                    :allow-empty="false"        
                    selected-label=""
                    select-label="" 
                    track-by="value" 
                    label="name" 
                    deselect-label="" 
                    object="false" 
                    class="multiselect-blue"
                    :disabled="props.disabled"
                >
                </multiselect>
            </div>

    </div>
</template>

<script setup>
import { ref, watch, toRefs } from 'vue';
import Multiselect from '@suadelabs/vue3-multiselect';
import '@suadelabs/vue3-multiselect/dist/vue3-multiselect.css';

const props = defineProps({
    modelValue: { type: String, default: '' },
    options: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    title: { type: String, default: "" },
    onChange: Function
});

const { modelValue, options } = toRefs(props);

const emit = defineEmits(['update:modelValue']);

const item = ref(null);

watch(item, (newValue) => {
    if (newValue) {
        emit('update:modelValue', newValue.value);
        if (typeof props.onChange === 'function') {
            props.onChange(newValue.value)
        }
    } else {
        emit('update:modelValue', null);
    }
}, { deep: true });

watch([modelValue, options], ([newModelValue, newOptions]) => {
    const selectedItem = newOptions.find(option => option.value === newModelValue);
    if (selectedItem) {
        item.value = selectedItem;
    } else {
        item.value = null;  // Reset item if no matching option is found
    }
}, { immediate: true });
</script>

<style>
.multiselect__select {
    height: 46px;
}

.multiselect__tags {
    height: 54px;
    padding: 16px 0px 0 8px;
    border-radius: 1px;
    border: 1px solid #c7c7c7;
}
.multiselect__tags:hover {
    border: 1px solid #0045c4; /* Change border color on hover */
}

.multiselect__tags:focus {
    color: #4361ee;
}

.select2 .multiselect__option--highlight {
    background: #fff;
    color: #4361ee;

}

.select2 .multiselect__option--selected {
    background-color: rgba(27, 85, 226, 0.23921568627450981);
    color: #4361ee;
    font-weight: normal;
}

.select2 .multiselect__option--disabled {
    background: inherit !important;
}

.select2 .multiselect__tag {
    color: #000;
    background: #e4e4e4;
}

.select2 .multiselect__tag-icon:after {
    color: #000 !important;
}

/* .select2 .multiselect__tag-icon:focus,
.multiselect__tags,
.select2 .multiselect__tag-icon:hover {
    background: inherit;
} */

.select2-container .select2-selection--single {
    height: 55px;
    width: 100%;
    border: 1px solid #c5c5c5;

}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 54px;
    padding-left: 25px;

}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 15px;
    border-color: #007bff; /* Change as needed */
}

</style>